import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Subconversation`}</h1>
    <ul>
      <li parentName="ul">{`Consider a `}<a parentName="li" {...{
          "href": "/subconversation",
          "title": "subconversation"
        }}>{`subconversation`}</a>{` as some subset of rows and columns from the total `}<a parentName="li" {...{
          "href": "/polis-opinion-matrix",
          "title": "polis opinion matrix"
        }}>{`polis opinion matrix`}</a>{` for a given `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` in the `}<a parentName="li" {...{
          "href": "/post-conversation-analysis",
          "title": "post conversation analysis"
        }}>{`post conversation analysis`}</a>{``}</li>
      <li parentName="ul">{`Ie., in the case of "`}<strong parentName="li">{`Bowling Green Daily News`}</strong>{` Bowling Green, Kentucky, USA" in `}<a parentName="li" {...{
          "href": "/Case-studies",
          "title": "⚗️ Case studies"
        }}>{`⚗️ Case studies`}</a>{` there were hundreds of comments but around 20 on opiods and 20 on homelessness. It is interesting to consider all participants but only those specific statements when clustering and producing heatmaps and statistics to drill down.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      